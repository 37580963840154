@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* breakpoint */
/* Dimensions */
/* Colors */
/* Fonts */
.wrapper {
  background: #eff2f9;
  max-width: 192rem;
  min-height: 100vh; }

header {
  padding: 2.8rem 1rem 2.5rem 2rem; }

footer {
  padding: 2.8rem 2rem 4rem 2rem; }

.content {
  display: flex;
  flex-flow: column nowrap;
  opacity: 0;
  transition: opacity 0.2s linear; }

.wrapper-mounted .content {
  opacity: 1; }

.component {
  width: 100%; }

.layout-404 {
  background: #ffffff url(/static/img/404.png) no-repeat right bottom;
  background-size: 80rem;
  background-attachment: fixed; }

@media screen and (min-width: 950px) {
  header {
    height: 100vh;
    left: 0;
    padding: 4.8rem 6.5rem 0 6rem;
    position: fixed;
    top: 0;
    width: 33rem; }
  footer {
    bottom: 0;
    left: 0;
    padding: 0 6.5rem 4.8rem 6rem;
    position: fixed;
    width: 33rem; }
  .content {
    overflow: hidden;
    padding: 4.8rem 0 2.8rem;
    position: relative; }
  .layout-even .content,
  .layout-2-3 .content,
  .layout-1column .content {
    margin-left: 33rem;
    width: calc(100% - 33rem - 2rem); } }

@media screen and (min-width: 1024px) {
  .content {
    flex-flow: row nowrap; }
  .column:first-child {
    margin-right: 1rem; }
  .column:last-child {
    margin-left: 1rem; }
  .layout-even .column {
    width: 50%; }
  .layout-2-3 .column:first-child {
    width: 70%; }
  .layout-2-3 .column:last-child {
    min-width: 35rem;
    width: 30%; }
  .layout-1column .content {
    display: flex;
    justify-content: left; }
  .layout-1column .component {
    width: 100%; } }

@media screen and (min-width: 1280px) {
  .layout-1column .component {
    width: 75%; } }

html {
  box-sizing: border-box;
  font-size: 62.5%; }

/* allow modules to change the box-sizing */
*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5; }

html,
body {
  background: #eff2f9;
  font-family: "Rubik", sans-serif; }

a {
  text-decoration: none; }

/* prettier-ignore */
.component {
  background: #ffffff;
  box-shadow: 0 0.2rem 0.2rem rgba(77, 94, 113, 0.1);
  margin: 0 0 0.8rem 0;
  padding: 2rem; }
  @media screen and (min-width: 950px) {
    .component {
      border-radius: 0.8rem;
      box-shadow: 0 0.4rem 1rem rgba(77, 94, 113, 0.1);
      margin: 0 0 2rem 0; } }
  @media screen and (min-width: 1280px) {
    .component {
      padding: 4rem; } }

.form {
  /* Status */
  /* Specific */ }
  .form label {
    cursor: pointer; }
  .form-block {
    align-items: flex-start;
    display: flex;
    flex-direction: column; }
  .form-group-container {
    flex: 3;
    width: 100%; }
  .form-row {
    align-items: center;
    display: flex;
    flex-direction: row; }
    .form-row > .form-group {
      flex: 1;
      margin-right: 1.5rem; }
      .form-row > .form-group:last-child {
        margin-right: 0; }
  .form .tips {
    flex: 2; }
    .form .tips .tip:not(:last-child) {
      margin-bottom: 4rem; }
  .form .component-form-title {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    margin-bottom: 2rem; }
    .form .component-form-title .link-icon {
      color: #d54227;
      font-size: 1.4rem;
      letter-spacing: 0.1rem;
      line-height: 1.7rem; }
      .form .component-form-title .link-icon .icon {
        fill: #d54227;
        margin-left: 1rem; }
  .form-group {
    margin-bottom: 2.5rem; }
    .form-group-checkbox {
      margin-top: 2rem;
      max-width: 100%; }
    .form-group-b-margin {
      margin-bottom: 4rem; }
    .form-group .link {
      color: #d54227;
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.8rem;
      margin-top: 1rem;
      text-align: right; }
    .form-group-link {
      align-items: center;
      display: flex;
      flex-direction: column; }
      .form-group-link > .link {
        margin-top: 0rem; }
  .form-help {
    color: #6c7f95;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-top: 0.5rem; }
  .form-error {
    color: #d54227;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-top: 0.5rem; }
  .form-line-checkbox, .form-line-radio {
    display: flex;
    margin-bottom: 1.5rem; }
  .form-line-radio {
    margin-bottom: 2.5rem; }
  .form-title {
    display: block;
    color: #212121;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2rem;
    margin-bottom: 1rem; }
    .form-title .subtitle {
      color: #6c7f95;
      font-size: 1.4rem;
      font-weight: 400;
      margin-left: 0.8rem; }
  .form-title-with-link {
    align-items: flex-start;
    display: flex;
    flex-direction: column; }
  .form-subtitle {
    color: #6c7f95;
    font-size: 1.4rem; }
  .form-input, .form-select, .form-textarea {
    -webkit-appearance: none;
    appearance: none;
    background: #eff2f9;
    border: 0.1rem solid transparent;
    border-radius: 0.4rem;
    color: #212121;
    font-size: 1.6rem;
    line-height: 2rem;
    outline: none;
    padding: 1rem 2rem;
    width: 100%; }
    .form-input::placeholder, .form-select::placeholder, .form-textarea::placeholder {
      color: #6c7f95; }
    .form-input:disabled, .form-input:disabled::placeholder, .form-select:disabled, .form-select:disabled::placeholder, .form-textarea:disabled, .form-textarea:disabled::placeholder {
      color: #c0ccd9; }
    .form-input:focus, .form-select:focus, .form-textarea:focus {
      border-color: #c0ccd9;
      box-shadow: 0 0.4rem 2rem rgba(77, 94, 113, 0.15); }
  .form-textarea {
    height: 13rem; }
  .form-select-container {
    position: relative; }
    .form-select-container .icon {
      fill: #d54227;
      position: absolute;
      right: 2rem;
      top: 1.8rem; }
  .form-checkbox, .form-radio {
    -webkit-appearance: none;
    appearance: none;
    background: #c0ccd9;
    border: none;
    display: block;
    flex-shrink: 0;
    height: 1.4rem;
    margin-right: 1rem;
    outline: none;
    position: relative;
    width: 1.4rem; }
  .form-checkbox {
    border-radius: 0.2rem; }
    .form-checkbox:checked {
      background: #d54227; }
      .form-checkbox:checked:before {
        content: '\2713';
        color: #ffffff;
        position: absolute;
        top: 0;
        left: 0.2rem;
        font-size: 1.1rem; }
  .form-radio {
    border-radius: 50%; }
    .form-radio:checked {
      background: rgba(213, 66, 39, 0.5);
      border: 0.3rem solid #d54227; }
  .form-input-locked, .form-input-eye {
    display: block;
    position: relative; }
    .form-input-locked .icon, .form-input-eye .icon {
      fill: #6c7f95;
      position: absolute;
      right: 2rem;
      top: 1.2rem; }
    .form-input-locked .icon-container, .form-input-eye .icon-container {
      cursor: pointer;
      color: #6c7f95;
      position: absolute;
      right: 2rem;
      top: 1rem; }
  .form-link-disabled {
    border-bottom: 0.2rem solid #d54227;
    opacity: 0.5;
    text-align: left; }
  .form-footer {
    align-items: center;
    border-bottom: 0.1rem solid #c0ccd9;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    padding: 3rem 0;
    position: relative; }
    .form-footer .btn:not(:last-child) {
      margin-right: 2rem; }
    .form-footer .go-back {
      color: #d54227;
      display: flex;
      font-size: 1.4rem;
      font-weight: 500;
      left: 0;
      letter-spacing: 0.1rem;
      line-height: 1.7rem;
      margin-top: 1rem;
      position: absolute;
      text-align: right; }
      .form-footer .go-back .icon {
        fill: #d54227; }
  .form-disabled .form-title,
  .form-disabled .form-subtitle,
  .form-disabled .form-input,
  .form-disabled .form-select,
  .form-disabled .form-textarea {
    color: #6c7f95;
    cursor: default; }
  .form-disabled .form-select-container .icon {
    fill: #6c7f95; }
  .form-disabled .btn,
  .form-disabled .btn:hover {
    background: #eff2f9;
    color: #c0ccd9;
    cursor: default; }
  .form-error .form-title,
  .form-error .form-subtitle,
  .form-error .form-input,
  .form-error .form-textarea,
  .form-error .form-select,
  .form-error .form-title:focus,
  .form-error .form-subtitle:focus,
  .form-error .form-input:focus,
  .form-error .form-textarea:focus,
  .form-error .form-select:focus {
    color: #d54227;
    border-color: #d54227; }
  .form-input-disabled {
    color: #c0ccd9; }
    .form-input-disabled .icon {
      fill: #c0ccd9; }
  .form-birthdate, .form-location, .form-name {
    display: flex; }
    .form-birthdate .form-subgroup, .form-location .form-subgroup, .form-name .form-subgroup {
      display: flex;
      flex-direction: column;
      margin-right: 2rem;
      flex: 1; }
    .form-birthdate .form-subgroup:last-child, .form-location .form-subgroup:last-child, .form-name .form-subgroup:last-child {
      margin-right: 0; }
  .form-birthdate input {
    text-align: center; }
  .form-birthdate .form-input {
    padding: 1rem;
    width: 6rem; }
    .form-birthdate .form-input#year {
      width: 10rem; }
  .form-location .form-input#zipcode {
    padding: 1rem;
    width: 10rem; }
  .form-location .form-subgroup:last-child {
    flex-grow: 1; }
  @media screen and (min-width: 950px) {
    .form-block {
      flex-direction: row; }
    .form-group-container {
      width: auto; }
    .form-footer {
      border-bottom: none;
      border-top: 0.1rem solid #c0ccd9;
      padding: 3rem 0 0;
      margin: 4rem 0 0; } }

.form .react-phone-number-input__row {
  background: #eff2f9;
  border: 0.1rem solid transparent;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  line-height: 2rem; }

.form .react-phone-number-input--focus .react-phone-number-input__row {
  border-color: #c0ccd9;
  box-shadow: 0 0.4rem 2rem rgba(77, 94, 113, 0.15); }

.form .react-phone-number-input__icon {
  border: none; }
  .form .react-phone-number-input__icon-image {
    display: block; }

.form .react-phone-number-input__input::placeholder {
  color: #6c7f95; }

.form .react-phone-number-input__input {
  background: transparent;
  border-bottom: none;
  font-size: 1.6rem;
  height: auto;
  line-height: 2rem;
  padding: 1rem 2rem 1rem 0; }

.form .react-phone-number-input__country {
  margin-right: 0;
  padding: 0 1rem; }

.form .react-phone-number-input__input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #eff2f9 inset; }

.form .form-error .react-phone-number-input__row {
  color: #d54227;
  border-color: #d54227; }

header {
  background: #ffffff;
  border-bottom: 0.1rem solid #eff2f9; }
  header .logo {
    width: 16rem; }
    header .logo svg {
      width: 100%; }
  header .menu-mobile {
    align-items: center;
    display: flex;
    justify-content: space-between; }
  header .btn-menu {
    height: 5.1rem; }
  header .menu,
  header footer,
  header .btn-close {
    display: none; }
  @media screen and (max-width: 949px) {
    header.menu-open {
      left: 0;
      position: fixed;
      right: 0;
      z-index: 100; }
      header.menu-open .menu-burger {
        display: none; }
      header.menu-open .btn-close {
        display: block; }
      header.menu-open .menu {
        background: #ffffff;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        left: 0;
        overflow-y: auto;
        position: fixed;
        right: 0;
        top: 10rem;
        padding: 2rem;
        z-index: 100; }
      header.menu-open footer {
        bottom: 2rem;
        display: block;
        left: 2rem;
        padding: 0;
        position: fixed;
        z-index: 100; } }
  @media screen and (min-width: 950px) {
    header {
      background: transparent;
      border-bottom: none; }
      header .logo {
        margin-bottom: 5rem;
        width: auto; }
      header .menu {
        display: block; } }

footer {
  background: #ffffff; }
  footer .footer-footer {
    align-items: flex-end;
    display: flex;
    justify-content: space-between; }
    footer .footer-footer .logo {
      width: 12rem; }
  @media screen and (min-width: 950px) {
    footer {
      background: transparent; } }

.footer-list a {
  color: #6c7f95;
  font-size: 1.3rem;
  line-height: 2.4rem;
  cursor: pointer; }
  .footer-list a:hover {
    color: #212121; }

.menu .select {
  position: relative; }
  .menu .select .icon {
    position: absolute;
    right: 0;
    top: 0.6rem;
    width: 1.2rem; }
    .menu .select .icon svg {
      fill: #d54227; }

.menu select {
  background: none;
  border: none;
  border-radius: 0;
  border-bottom: 0.1rem solid #c0ccd9;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  margin-bottom: 3.2rem;
  padding-bottom: 1rem;
  outline: none;
  width: 100%;
  -webkit-appearance: none;
  appearance: none; }
  .menu select:disabled {
    cursor: default;
    opacity: 1;
    color: #000000; }

.menu .menu-list {
  margin-bottom: 4rem; }
  .menu .menu-list a {
    align-items: center;
    color: #212121;
    display: flex;
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
    line-height: 2rem;
    margin-bottom: 3rem;
    text-transform: uppercase; }
    .menu .menu-list a .icon {
      display: block;
      margin-right: 1.2rem;
      width: 22px; }
      .menu .menu-list a .icon svg {
        fill: #212121;
        stroke-color: #212121; }
    .menu .menu-list a:hover, .menu .menu-list a.active {
      color: #d54227; }
      .menu .menu-list a:hover .icon svg, .menu .menu-list a.active .icon svg {
        fill: #d54227;
        stroke-color: #d54227; }

.menu li:last-child a {
  margin-bottom: 0; }

@media screen and (min-width: 950px) {
  .menu .menu-list a {
    margin-bottom: 1.5rem; } }

.react-phone-number-input__row {
  /* This is done to stretch the contents of this component */
  display: flex;
  align-items: center; }

.react-phone-number-input__phone {
  /* The phone number input stretches to fill all empty space */
  flex: 1;
  /* The phone number input should shrink
     to make room for the extension input */
  min-width: 0; }

.react-phone-number-input__icon {
  /* The flag icon size is 4x3 hence the exact `width` and `height` values */
  width: 1.24em;
  height: 0.93em;
  /* Makes sure `width`x`height` is exactly `4x3` and `border` width is not included in it */
  box-sizing: content-box; }

.react-phone-number-input__icon--international {
  /* The international icon size is square */
  /* and also has no border hence the `2 * 1px` `width` and `height` compensation */
  width: calc(0.93em + 2px);
  height: calc(0.93em + 2px);
  /* The international icon size is square hence the exact `padding` value */
  /* for precise alignment with `4x3` sized country flags. */
  padding-left: 0.155em;
  padding-right: 0.155em;
  border: none; }

.react-phone-number-input__error {
  margin-left: calc(1.24em + 2px + 0.3em + 0.35em + 0.5em);
  margin-top: calc(0.3rem);
  color: #d30f00; }

.react-phone-number-input__icon-image {
  max-width: 100%;
  max-height: 100%; }

/* Removes `<input type="number"/>` up/down arrows in Webkit browsers. */
.react-phone-number-input__ext-input::-webkit-inner-spin-button,
.react-phone-number-input__ext-input::-webkit-outer-spin-button {
  margin: 0 !important;
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important; }

.react-phone-number-input__ext-input {
  width: 3em; }

.react-phone-number-input__ext {
  white-space: nowrap; }

.react-phone-number-input__ext,
.react-phone-number-input__ext-input {
  margin-left: 0.5em; }

/* Styling native country `<select/>`. */
.react-phone-number-input__country--native {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0.5em; }

.react-phone-number-input__country-select {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer; }

.react-phone-number-input__country-select-arrow {
  display: block;
  content: '';
  width: 0;
  height: 0;
  margin-bottom: 0.1em;
  margin-top: 0.3em;
  margin-left: 0.3em;
  border-width: 0.35em 0.2em 0 0.2em;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
  color: #b8bdc4;
  opacity: 0.7;
  transition: color 0.1s; }

/* Something from stackoverflow. */
.react-phone-number-input__country-select-divider {
  font-size: 1px;
  background: black; }

.react-phone-number-input__country-select:focus
+ .react-phone-number-input__country-select-arrow,
.react-phone-number-input__country.rrui__select--focus .rrui__select__arrow {
  color: #03b2cb; }

/* Styling phone number input */
.react-phone-number-input__input {
  height: calc(0.3rem * 6);
  outline: none;
  border-radius: 0;
  padding: 0;
  appearance: none;
  border: none;
  border-bottom: 1px solid #c5d2e0;
  transition: border 0.1s;
  font-size: inherit; }

.react-phone-number-input__input:focus {
  border-color: #03b2cb; }

.react-phone-number-input__input--disabled {
  cursor: default; }

.react-phone-number-input__input--invalid,
.react-phone-number-input__input--invalid:focus {
  border-color: #eb2010; }

/* Overrides Chrome autofill yellow background color */
.react-phone-number-input__input:-webkit-autofill {
  box-shadow: 0 0 0 1000px white inset; }

/* A small fix for `react-responsive-ui` */
.react-phone-number-input__country .rrui__select__button {
  border-bottom: none; }

.article {
  align-items: flex-start;
  color: #212121;
  display: flex;
  flex-direction: column; }
  .article-image {
    margin: 0 0 3rem 0;
    width: 100%; }
  .article-date {
    color: #6c7f95;
    font-size: 1.3rem;
    line-height: 1.5rem;
    margin-bottom: 0.4rem; }
  .article-title {
    color: #212121;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2rem;
    margin-bottom: 0.8rem; }
    .article-title-unread:before {
      background: #d54227;
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 1.2rem;
      margin-right: 1.5rem;
      width: 1.2rem; }
  .article-content {
    color: #4d5e71;
    font-size: 1.4rem;
    line-height: 2rem; }
  @media screen and (min-width: 1280px) {
    .article {
      align-items: center;
      flex-direction: row; }
      .article-image {
        margin: 0 3rem 0 0;
        width: auto; } }

.activate-lre .icon {
  display: flex;
  fill: #d54227;
  justify-content: center; }

.activate-lre .title.medium {
  font-size: 1.6rem;
  line-height: 2rem;
  margin: 1rem 0; }

.activate-lre p {
  color: #6c7f95;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-bottom: 3rem; }

.activate-lre .lre-activated {
  color: #3f995f;
  display: flex;
  font-size: 1.5rem;
  font-weight: 500; }
  .activate-lre .lre-activated .icon {
    fill: #3f995f;
    margin-right: 1rem; }

.balance {
  color: #4d5e71;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between; }
  .balance-amount {
    border-left: 0.5rem solid #eff2f9;
    margin-bottom: 2rem;
    padding-left: 1.5rem; }
  .balance-currency {
    margin-left: 1rem; }
  .balance-label {
    color: #4d5e71;
    font-size: 1.4rem;
    line-height: 1.8rem; }
  .balance.current-balance .balance-amount {
    font-size: 6rem;
    font-weight: 500;
    line-height: 7.1rem; }
  .balance-positive {
    color: #3f995f; }
  .balance-negative {
    color: #d54227; }
  .balance.next-payment .balance-amount {
    color: #6c7f95;
    font-size: 3.5rem;
    line-height: 4.2rem; }
  .balance .payment-error {
    color: #d54227; }
  .balance .balance-label .date {
    white-space: nowrap; }

.component-balance-group {
  display: flex;
  flex-direction: column; }
  .component-balance-group .right-block {
    flex: 1; }
  .component-balance-group .status-description {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center; }
    .component-balance-group .status-description > .green {
      color: #3f995f; }
  .component-balance-group .pay-now {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between; }
    .component-balance-group .pay-now-btn {
      justify-content: center;
      text-align: center;
      margin-top: 2rem;
      width: 100%;
      min-width: 15rem;
      max-width: 30rem; }
    .component-balance-group .pay-now .pay-now-text {
      align-items: center;
      color: #6c7f95;
      display: flex;
      flex-direction: row;
      font-size: 1.3rem;
      line-height: 1.6rem;
      margin-top: 2rem; }
      .component-balance-group .pay-now .pay-now-text .icon {
        fill: #6c7f95;
        margin-right: 1rem; }
  @media screen and (min-width: 950px) {
    .component-balance-group {
      flex-direction: row; }
      .component-balance-group .balance:not(:last-child) {
        margin-right: 2rem; }
      .component-balance-group .balance-label {
        font-size: 1.5rem;
        line-height: 2.2rem; } }
  @media screen and (min-width: 1280px) {
    .component-balance-group .balance:not(:last-child) {
      margin-right: 4rem; } }

.btn {
  -webkit-appearance: none;
  appearance: none;
  align-items: center;
  border: none;
  border-radius: 3rem;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  line-height: 1.8rem;
  padding: 1rem 2rem;
  outline: none; }
  .btn .icon {
    margin-right: 0.8rem; }
    .btn .icon svg {
      display: block; }

.btn-primary {
  background: #d54227;
  color: #ffffff; }
  .btn-primary .icon {
    fill: #ffffff; }
  .btn-primary:hover, .btn-primary:active {
    box-shadow: 0 0.4rem 1rem rgba(213, 66, 39, 0.35); }
  .btn-primary:disabled {
    cursor: default;
    opacity: 0.5; }
    .btn-primary:disabled:hover, .btn-primary:disabled:active {
      box-shadow: none; }

.btn-secondary {
  background: #6c7f95;
  color: #ffffff; }
  .btn-secondary .icon {
    fill: #6c7f95; }
  .btn-secondary:hover, .btn-secondary:active {
    box-shadow: 0 0.4rem 1rem rgba(77, 94, 113, 0.25); }

.btn-transparent {
  background: transparent;
  border: 0.1rem solid #d54227;
  color: #d54227; }
  .btn-transparent .icon {
    fill: #d54227; }
  .btn-transparent:hover, .btn-transparent:active {
    box-shadow: 0 0.5rem 1rem rgba(213, 66, 39, 0.2); }

.btn-centered {
  margin-left: auto;
  margin-right: auto; }

.btn-loading {
  cursor: default;
  position: relative;
  opacity: 0.5; }
  .btn-loading:hover, .btn-loading:active {
    box-shadow: none; }

.btn-text {
  padding: 0; }

@media screen and (min-width: 950px) {
  .btn-large {
    padding: 1.5rem 3rem; }
  .btn-xlarge {
    padding: 1.5rem 5rem; } }

.detail-page-title-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 3rem; }
  .detail-page-title-container .page-title {
    align-items: center;
    display: flex;
    margin-bottom: 2rem; }
  .detail-page-title-container .title {
    border-left: 0.1rem solid #c0ccd9;
    margin: 0 0 0 1.5rem;
    padding-left: 1.5rem; }

@media screen and (min-width: 768px) {
  .detail-page-title-container {
    align-items: center;
    flex-direction: row; }
    .detail-page-title-container .page-title {
      margin-bottom: 0; } }

.download-document-preview {
  align-items: center;
  display: flex;
  flex-direction: column; }
  .download-document-preview .preview {
    width: 100%;
    max-width: 25rem;
    height: 13rem;
    background: #eff2f9;
    margin: 0 2rem;
    margin-bottom: 2.3rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    .download-document-preview .preview .icon {
      vertical-align: middle;
      fill: #c0ccd9; }
  .download-document-preview .btn {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 1rem 3rem;
    text-align: center; }
    .download-document-preview .btn .icon {
      margin-right: 1rem;
      fill: white; }

.download-document-link {
  align-items: center;
  color: #d54227;
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  line-height: 1.5rem; }
  .download-document-link .icon {
    fill: #d54227;
    margin-right: 0.8rem; }

.block-content {
  padding: 2rem;
  text-align: center; }
  .block-content.has-shadow {
    box-shadow: 0 0.4rem 2rem rgba(77, 94, 113, 0.15); }

@media screen and (min-width: 950px) {
  .block-content {
    padding: 3.2rem; } }

.file {
  align-items: center;
  display: flex; }
  .file .icon {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 4rem;
    justify-content: center;
    margin-right: 1rem;
    width: 4rem; }
  .file-content {
    color: #4d5e71;
    font-size: 1.5rem;
    line-height: 2rem;
    flex-grow: 1; }
  .file-status {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-left: 1rem; }
    .file-status .status {
      color: #3f995f;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem;
      text-align: right; }
    .file-status .last-activity {
      color: #6c7f95;
      font-size: 1.2rem;
      line-height: 2rem;
      text-align: right; }
  .file-title {
    display: inline;
    color: #212121;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.4rem; }
    .file-title > h4 {
      display: inline;
      margin-right: 0.5rem; }
  .file.closed .icon {
    background: #eff2f9;
    fill: #c0ccd9; }
  .file.closed .file-content {
    color: #6c7f95; }
  .file.closed .file-title {
    color: #6c7f95; }
  .file.closed .status {
    color: #4d5e71; }

.file-info {
  align-items: center;
  color: #4d5e71;
  display: flex;
  font-size: 1.3rem;
  line-height: 1.5rem;
  justify-content: space-between;
  margin-bottom: 2rem; }
  .file-info .file-label {
    align-items: center;
    display: flex;
    flex: 1;
    font-weight: 500; }
    .file-info .file-label .icon {
      display: block;
      margin-right: 1rem;
      fill: #d54227; }
  .file-info .file-detail {
    color: #6c7f95;
    white-space: nowrap;
    margin-left: 1rem; }
  .file-info:last-child {
    margin-bottom: 0; }

.icon {
  flex-shrink: 0;
  fill: white; }
  .icon svg {
    display: block; }

.icon-shield {
  background: #7cbae3; }

.icon-bolt {
  background: #ffd345; }

.icon-meeting {
  background: #3f995f; }

.icon-heat {
  background: #d54227; }

.icon-elevator {
  background: #d54227; }

.icon-electricity {
  background: #ffd345; }

.icon-cleaning {
  background: #3f995f; }

.icon-work {
  background: #ffd345; }

.icon-small-work {
  background: #ffd345; }

.icon-legal {
  background: #7cbae3; }

.icon-other {
  background: #d54227; }

.icon-heater {
  background: #d54227; }

.icon-door {
  background: #7cbae3; }

.icon-more {
  background: #d54227; }

.icon-money {
  background: #ffd345; }

.icon-shield-plus {
  background: #7cbae3; }

.icon-water {
  background: #7cbae3; }

.link {
  align-items: center;
  display: flex;
  color: #d54227;
  cursor: pointer; }
  .link .link-label {
    position: relative;
    white-space: nowrap; }
    .link .link-label:after {
      background: #d54227;
      bottom: -0.2rem;
      content: '';
      height: 0.2rem;
      left: auto;
      position: absolute;
      right: 0;
      width: 0;
      /* Uncomment for transition effect
      transition: width 0.2s ease-in-out;
      */ }
  .link .icon {
    display: block;
    fill: #d54227;
    margin-right: 1rem; }
  .link:hover .link-label:after {
    left: 0;
    right: auto;
    width: 100%; }

.link-icon {
  align-items: center;
  color: #4d5e71;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.8rem;
  justify-content: space-between; }
  .link-icon.link-icon-left .icon {
    margin-right: 1rem; }

.open-modal {
  overflow-y: hidden; }
  .open-modal .modal.opened {
    display: block;
    opacity: 1; }

.modal {
  display: none;
  opacity: 0;
  transition: opacity 0.5s linear 0.1s;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto; }

.modal-background {
  background: rgba(0, 0, 0, 0.25);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1; }

.modal-container {
  align-items: center;
  display: flex;
  margin: 8rem 0;
  min-height: calc(100% - 16rem);
  position: relative;
  z-index: 2; }

.modal-content {
  background: #ffffff;
  border-radius: 0.4rem;
  box-shadow: 0 0.2rem 0.2rem rgba(77, 94, 113, 0.1);
  margin: 0 auto;
  max-width: 72rem;
  padding: 3rem 4rem 4rem;
  width: 100%; }

.modal-header {
  align-items: center;
  border-bottom: 0.1rem solid #c0ccd9;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  padding-bottom: 3rem; }
  .modal-header .title {
    margin-bottom: 0; }
  .modal-header .icon {
    cursor: pointer;
    fill: #d54227; }

.notification {
  align-items: center;
  border-radius: 5.5rem;
  display: flex;
  font-size: 1.5rem;
  justify-content: space-between;
  line-height: 2rem;
  max-width: 100%;
  padding: 1rem;
  width: 50rem; }
  .notification-content {
    align-items: center;
    display: flex; }
    .notification-content .icon {
      margin: 0 1.5rem 0 0.5rem; }
  .notification .label {
    font-weight: 500;
    margin-right: 1rem;
    flex-grow: 0; }
  .notification .btn-close {
    margin: -1rem 0;
    cursor: pointer; }
  .notification-error {
    background: #d54227;
    color: #ffffff; }
    .notification-error .icon {
      fill: #ffffff; }
  .notification-success {
    background: #2f864d;
    color: #ffffff; }
    .notification-success .icon {
      fill: #ffffff; }
  .notification-info {
    background: #7cbae3;
    color: #212121; }
    .notification-info .icon {
      fill: #212121; }
  .notification-warning {
    background: #ffc645;
    color: #212121; }
    .notification-warning .icon {
      fill: #212121; }
  .notification .closed {
    display: none; }

.toast {
  position: fixed;
  /* Sit on top of the screen */
  z-index: 1001;
  /* Add a z-index if needed */
  left: 50%;
  /* Center the snackbar */
  top: 30px;
  /* 30px from the bottom */
  margin-left: -25rem; }

.fade {
  animation: fadeout 0.5s forwards; }

@keyframes fadeout {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.title {
  display: flex;
  font-size: 1.4rem;
  justify-content: space-between;
  letter-spacing: 0.05rem;
  line-height: 1.7rem;
  margin-bottom: 2rem; }
  .title .link {
    font-weight: 500; }
  .title .label {
    color: #6c7f95;
    text-transform: uppercase; }
  .title.centered {
    justify-content: center; }
  .title.medium {
    font-size: 2.4rem;
    line-height: 2.8rem; }
  .title.large {
    font-size: 2.8rem;
    line-height: 3.6rem; }
  .title.medium .label, .title.large .label {
    color: #212121;
    font-weight: 500;
    text-transform: none; }

.search-input {
  align-items: center;
  background: #eff2f9;
  border-radius: 10rem;
  display: flex;
  justify-content: space-between;
  padding: 0.6rem 2rem; }
  .search-input.large {
    padding: 1.2rem 2rem; }
  .search-input input {
    background: transparent;
    border: none;
    color: #212121;
    display: block;
    flex-grow: 1;
    font-family: "Rubik", sans-serif;
    font-size: 1.6rem;
    line-height: 2rem;
    outline: none; }
    .search-input input::placeholder {
      color: #6c7f95; }
  .search-input .btn-icon .icon {
    display: block;
    fill: #6c7f95;
    height: 2.4rem;
    width: 2.4rem; }
  .search-input .btn-icon:hover .icon {
    fill: #d54227; }

.sub-items {
  color: #6c7f95;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem; }
  .sub-items .item {
    align-items: flex-start;
    border: 0.1rem solid #eff2f9;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-right: 2rem; }
    .sub-items .item:last-child {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem; }
    .sub-items .item:first-child {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem; }
    .sub-items .item:not(:first-child) {
      border-top: 0rem; }

@media screen and (min-width: 768px) {
  .sub-items .item {
    align-items: center;
    flex-direction: row; } }

.component-tabs-title {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  margin-top: -1rem; }
  .component-tabs-title > * {
    margin-top: 1rem; }
  .component-tabs-title .title {
    flex: 1;
    margin-bottom: 0; }

.tab-list {
  border-bottom: 1px solid #ccc;
  display: flex;
  margin-bottom: 2.5rem; }

.tab-list-item {
  border-bottom: 0.1rem solid transparent;
  cursor: pointer;
  display: flex;
  font-size: 1.8rem;
  line-height: 2.2rem;
  padding: 1rem 0 1.8rem; }
  .tab-list-item .icon {
    fill: #d54227;
    margin-right: 1rem; }
    .tab-list-item .icon.icon-angle-down {
      display: none; }
  .tab-list-item:hover {
    color: #d54227; }
  .tab-list-item:last-child {
    margin-right: 0; }

.tab-active .tab-list-item {
  border-bottom-color: #c0ccd9;
  margin: 0 0 3rem; }
  .tab-active .tab-list-item .icon.icon-angle-down {
    display: block; }
  .tab-active .tab-list-item .icon.icon-angle-right {
    display: none; }

.tabs .tab-content {
  display: none; }

.tabs .tab-active .tab-list-item {
  color: #d54227; }

.tabs .tab-active .tab-content {
  display: block; }

@media screen and (min-width: 950px) {
  .tab-list-item {
    border-bottom: 0.3rem solid transparent;
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 0 4rem -0.1rem 0; }
  .tab-list-active {
    border-bottom-color: #d54227;
    color: #d54227;
    padding-bottom: 1.5rem; } }

.toggle-lines {
  border-bottom: 0.1rem solid #c0ccd9;
  margin-bottom: 4rem;
  padding-bottom: 4rem; }
  .toggle-lines.no-border {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0; }

.toggle-line {
  align-items: flex-start;
  display: flex;
  padding: 2rem 0;
  margin: 0 -2rem;
  padding: 2rem; }
  .toggle-line:nth-child(odd) {
    background: #f7faff; }
  .toggle-line .toggle-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* Icons */ }
    .toggle-line .toggle-icon-icon {
      background: #c0ccd9;
      color: #ffffff; }
      .toggle-line .toggle-icon-icon svg {
        display: block; }
    .toggle-line .toggle-icon-circle {
      align-items: center;
      border-radius: 50%;
      color: #4d5e71;
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      height: 3.8rem;
      justify-content: center;
      margin-right: 2rem;
      width: 3.8rem; }
    .toggle-line .toggle-icon-green, .toggle-line .toggle-icon-accepted {
      background: #3f995f;
      color: #ffffff; }
    .toggle-line .toggle-icon-yellow {
      background: #ffd345; }
    .toggle-line .toggle-icon-grey {
      background: #eff2f9; }
    .toggle-line .toggle-icon-rejected {
      background: #d54227; }
      .toggle-line .toggle-icon-rejected .icon {
        fill: #ffffff; }
    .toggle-line .toggle-icon-empty {
      content: 'i'; }
      .toggle-line .toggle-icon-empty .icon {
        fill: transparent; }
  .toggle-line .icon-angle-down {
    display: none; }
  .toggle-line .icon-angle-right {
    fill: #6c7f95; }

.toggle-container {
  flex-grow: 1; }

.toggle-content {
  display: none;
  margin: 2rem 0 0 -5.8rem; }

.toggle-title {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 1rem 1.5rem;
  padding-right: 0; }
  .toggle-title .icon {
    margin: 0.3rem 1rem 0 0;
    fill: #c0ccd9; }
  .toggle-title .toggle-label {
    align-items: center;
    display: flex;
    flex-grow: 1; }
    .toggle-title .toggle-label .label-result {
      display: flex;
      flex-direction: column;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 3.2rem; }
    .toggle-title .toggle-label .result-value {
      color: #4d5e71;
      font-weight: 400;
      line-height: 3.2rem; }
    .toggle-title .toggle-label.has-result {
      align-items: flex-start; }
      .toggle-title .toggle-label.has-result .label-value {
        line-height: 2rem; }
  .toggle-title .toggle-thousands {
    color: #6c7f95;
    flex-grow: 0;
    font-size: 1.5rem;
    line-height: 2rem; }

.toggle-open .icon-angle-right {
  display: none; }

.toggle-open .icon-angle-down {
  display: block;
  fill: #d54227; }

.toggle-open .toggle-content {
  display: block; }

@media screen and (min-width: 768px) {
  .toggle-title {
    align-items: center;
    flex-direction: row; }
  .toggle-content {
    margin-left: 0; } }

@media screen and (min-width: 1280px) {
  .toggle-line {
    margin: 0 -4rem;
    padding: 2rem 4rem; } }

.inline-tag {
  display: inline-block;
  color: #4d5e71;
  background: #eff2f9;
  border-radius: 4px;
  padding: 0 0.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  white-space: nowrap; }
  .inline-tag > .restricted-access {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .inline-tag > .restricted-access > .icon {
      fill: #4d5e71;
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.6rem; }

.tags {
  display: flex;
  justify-content: center;
  margin: 2rem 0; }
  .tags .tag {
    margin-right: 0.8rem; }

.tag {
  background: #eff2f9;
  border-radius: 10rem;
  color: #6c7f95;
  font-size: 1.4rem;
  line-height: 1.9rem;
  padding: 0.6rem 1.4rem; }
  .tag .link-label:after {
    display: none; }
  .tag:hover {
    box-shadow: 0 0.2rem 0.5rem rgba(77, 94, 113, 0.25); }

.tip {
  border-top: 0.4rem solid #eff2f9;
  color: #6c7f95;
  padding: 2rem 0; }
  .tip-title {
    align-items: center;
    display: flex;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2rem;
    margin-bottom: 1rem; }
    .tip-title .icon {
      fill: #6c7f95;
      margin-right: 1rem; }
  .tip-content {
    font-size: 1.4rem;
    line-height: 1.8rem; }
  .tip .link {
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.8rem;
    margin-top: 1rem; }

@media screen and (min-width: 950px) {
  .tip {
    border-left: 0.4rem solid #eff2f9;
    border-top: none;
    margin-left: 2rem;
    padding: 2rem 0 2rem 3rem; } }

.step {
  color: #4d5e71;
  margin: 0 0 2rem 0;
  padding: 2rem; }
  .step .logo {
    margin-bottom: 4.5rem; }
  .step h3 {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 2rem;
    margin-bottom: 1rem; }
  .step p {
    font-size: 1.5rem;
    line-height: 2.2rem;
    margin-bottom: 1rem; }
  .step-status {
    color: #6c7f95;
    font-size: 1.4rem;
    line-height: 2.2rem; }

@media screen and (min-width: 1024px) {
  .step {
    position: sticky;
    top: 10rem;
    margin: 0 19rem 0 0;
    padding: 0;
    width: 27rem; } }

.amount-list-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
  font-size: 1.6rem;
  color: #212121;
  line-height: 2rem;
  margin-bottom: 1rem; }

.component-announcements .article:not(:last-child) {
  margin-bottom: 1.5rem; }

.component-announcements .article-title {
  margin-bottom: 0.4rem; }

.component-announcements .article-content {
  font-size: 1.4rem;
  line-height: 1.8rem; }

@media screen and (min-width: 950px) {
  .component-announcements .article-content {
    font-size: 1.5rem;
    line-height: 2.2rem; } }

.component-contact .contact-box {
  align-items: center;
  border-top: 0.1rem solid #c0ccd9;
  color: #6c7f95;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  padding-top: 3rem; }
  .component-contact .contact-box .small-title {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.2rem; }
  .component-contact .contact-box p {
    font-size: 1.5rem;
    line-height: 2.2rem;
    margin-bottom: 1rem; }

.charge-category-summary {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
  line-height: 2rem;
  padding-bottom: 0.9rem;
  padding-top: 0.9rem; }
  .charge-category-summary .icon {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 4rem;
    justify-content: center;
    margin-right: 1rem;
    width: 4rem; }
  .charge-category-summary .label {
    color: #212121;
    flex: 3;
    text-align: left; }
    .charge-category-summary .label.show_more {
      color: #d54227;
      font-size: 1.4rem;
      font-weight: 500;
      cursor: pointer; }
  .charge-category-summary .percent {
    color: #4d5e71;
    flex: 1;
    text-align: center; }
  .charge-category-summary .date {
    color: #4d5e71;
    flex: 2;
    text-align: center; }
  .charge-category-summary .amount {
    color: #4d5e71;
    flex: 1;
    text-align: right; }

.charge-category .charge-category-sub-items {
  margin-left: 2rem;
  margin-right: -2rem; }

.charge-category-sub-item {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .charge-category-sub-item .icon {
    margin-right: 1.4rem;
    fill: #d54227; }
  .charge-category-sub-item .name {
    align-items: center;
    display: flex;
    flex: 3;
    cursor: pointer; }
  .charge-category-sub-item .date {
    flex: 1; }
  .charge-category-sub-item .see-file {
    flex: 1;
    cursor: pointer; }
  .charge-category-sub-item .amount {
    flex: 1;
    text-align: right; }

.component-files .title {
  margin-bottom: 2rem; }

.component-files .component-content:not(:last-child) {
  margin-bottom: 4rem; }

.component-files .file:not(:last-child) {
  margin-bottom: 3rem; }

.sepa-form .hint-iban {
  color: #4d5e71;
  font-size: 1.4rem;
  margin-bottom: 2rem; }

.sepa-form .StripeElement {
  -webkit-appearance: none;
  appearance: none;
  background: #eff2f9;
  border: 0.1rem solid transparent;
  border-radius: 0.4rem;
  color: #212121;
  font-size: 1.6rem;
  line-height: 2rem;
  outline: none;
  padding: 1rem 2rem;
  width: 100%; }
  .sepa-form .StripeElement--disabled, .sepa-form .StripeElement--disabled::placeholder {
    color: #6c7f95; }
  .sepa-form .StripeElement--focus {
    border-color: #c0ccd9;
    box-shadow: 0 0.4rem 2rem rgba(77, 94, 113, 0.15); }
  .sepa-form .StripeElement--invalid {
    color: #d54227;
    border-color: #d54227; }

.form-iban-mandate {
  color: #6c7f95;
  font-size: 1.2rem;
  margin-top: 3rem; }

.payment {
  color: #4d5e71;
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
  justify-content: space-between;
  line-height: 2rem;
  padding-bottom: 0.9rem;
  padding-top: 0.9rem; }
  .payment-title {
    flex: 2;
    text-align: left; }
  .payment-date {
    flex: 1;
    text-align: center; }
  .payment-document-download {
    flex: 0.8;
    display: flex;
    align-items: center; }
  .payment-type {
    flex: 1;
    text-align: center; }
  .payment-amount {
    flex: 1;
    text-align: right; }
  .payment:nth-child(odd) {
    background-color: #f7faff;
    margin-left: -2rem;
    margin-right: -2rem;
    padding-left: 2rem;
    padding-right: 2rem; }
    @media screen and (min-width: 1280px) {
      .payment:nth-child(odd) {
        margin-left: -4rem;
        margin-right: -4rem;
        padding-left: 4rem;
        padding-right: 4rem; } }

.component-list .component-content:not(:last-child) {
  margin-bottom: 4rem; }

.component-list-item {
  margin: 0 -2rem;
  padding: 1.5rem 2rem; }
  .component-list-item:last-child {
    margin-bottom: -1.5rem; }
  .component-list-item:hover {
    background: #eff2f9; }

@media screen and (min-width: 1280px) {
  .component-list-item {
    margin: 0 -4rem;
    padding: 1.5rem 4rem; } }

.empty-list-item {
  color: #6c7f95;
  display: flex; }
  .empty-list-item .empty-title-container {
    display: flex;
    flex-direction: column;
    margin: 0; }
  .empty-list-item .icon {
    align-items: center;
    background-color: #eff2f9;
    border-radius: 50%;
    display: flex;
    height: 4rem;
    justify-content: center;
    margin-right: 1rem;
    width: 4rem;
    fill: #6c7f95; }
  .empty-list-item .empty-title {
    color: #4d5e71;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 500; }
  .empty-list-item .empty-subtitle {
    font-size: 1.5rem;
    line-height: 2rem; }

.contract {
  align-items: center;
  display: flex; }
  .contract .icon {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 4rem;
    justify-content: center;
    margin-right: 1rem;
    width: 4rem; }
  .contract-content {
    color: #4d5e71;
    font-size: 1.5rem;
    line-height: 2rem;
    flex-grow: 1;
    flex: 2; }
  .contract-price-period {
    color: #4d5e71;
    font-size: 1.5rem;
    line-height: 2rem;
    flex-grow: 1;
    flex: 1; }
    .contract-price-period .price {
      color: #4d5e71;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem; }
    .contract-price-period .period {
      color: #6c7f95;
      font-size: 1.2rem;
      line-height: 2rem; }
    @media screen and (max-width: 768px) {
      .contract-price-period {
        display: none; } }
  .contract-status {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-left: 1rem; }
    .contract-status .status {
      color: #3f995f;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem;
      text-align: right; }
  .contract-title {
    color: #212121;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2rem; }
  .contract.closed .icon {
    background: #eff2f9; }
  .contract.closed .contract-content {
    color: #6c7f95; }
  .contract.closed .contract-title {
    color: #6c7f95; }
  .contract.closed .status {
    color: #4d5e71; }

.contract-component-header {
  display: flex;
  color: #6c7f95;
  flex-direction: column; }
  .contract-component-header .contract-header-wrapper {
    display: flex;
    flex-direction: row; }
    @media screen and (max-width: 1024px) {
      .contract-component-header .contract-header-wrapper {
        justify-content: space-between; } }
    @media screen and (max-width: 950px) {
      .contract-component-header .contract-header-wrapper {
        flex-direction: column; } }
    .contract-component-header .contract-header-wrapper .contract-details {
      display: flex;
      flex-direction: column;
      margin-right: 10rem; }
      @media screen and (max-width: 1024px) {
        .contract-component-header .contract-header-wrapper .contract-details {
          margin-right: 0; } }
      @media screen and (max-width: 950px) {
        .contract-component-header .contract-header-wrapper .contract-details {
          padding-bottom: 1.5rem; } }
    .contract-component-header .contract-header-wrapper .contract-price {
      font-size: 3.5rem;
      color: #000000;
      border-left: solid 0.5rem #d54227;
      padding-left: 1.5rem; }
    .contract-component-header .contract-header-wrapper .contractor-details {
      font-size: 1.4rem;
      display: flex;
      flex-direction: column;
      padding-top: 1rem; }
    .contract-component-header .contract-header-wrapper .contractor-name {
      font-size: 1.6rem;
      font-weight: 500; }
  .contract-component-header .contract-summary {
    padding: 3rem 0; }
    .contract-component-header .contract-summary .title {
      font-size: 1.4rem; }
    .contract-component-header .contract-summary .summary-text {
      color: #6c7f95; }
  .contract-component-header .related-files-contracts {
    padding-bottom: 3rem; }

.list-header .horizontal-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -1rem; }
  .list-header .horizontal-container > * {
    margin-top: 1rem; }
  .list-header .horizontal-container .title {
    flex: 2;
    line-height: 2rem;
    margin-bottom: 0; }
  .list-header .horizontal-container .search-input {
    flex: 3; }

.list-header .filters {
  display: flex;
  margin-top: 3rem; }
  .list-header .filters .item {
    cursor: pointer;
    color: #6c7f95;
    font-size: 1.4rem;
    font-weight: 500;
    margin-right: 2rem; }
    .list-header .filters .item:hover {
      color: #d54227; }
    .list-header .filters .item:last-child {
      margin-right: 0rem; }
    .list-header .filters .item.selected {
      color: #d54227; }

.list-header .line-separator {
  height: 0.1rem;
  background-color: #c0ccd9;
  margin-top: 2rem;
  margin-bottom: 3rem; }

.list-pagination {
  display: flex;
  justify-content: space-between; }
  .list-pagination .pagination-position {
    color: #6c7f95;
    font-size: 1.4rem;
    line-height: 3.5rem;
    vertical-align: bottom; }
  .list-pagination .pagination-pages {
    display: flex;
    flex-direction: row; }
    .list-pagination .pagination-pages .pagination-item {
      background-color: #eff2f9;
      border-radius: 50%;
      color: #6c7f95;
      height: 3.5rem;
      line-height: 3.5rem;
      margin-left: 0.6rem;
      text-align: center;
      width: 3.5rem;
      cursor: pointer; }
      .list-pagination .pagination-pages .pagination-item-active {
        background-color: #6c7f95;
        color: #ffffff;
        cursor: text; }
      .list-pagination .pagination-pages .pagination-item-more {
        cursor: text; }
    .list-pagination .pagination-pages .pagination-previous .icon,
    .list-pagination .pagination-pages .pagination-next .icon {
      display: block;
      height: 1.2rem;
      margin: 1.1rem 0 0 1.4rem;
      width: 0.7rem;
      fill: #6c7f95; }
      .list-pagination .pagination-pages .pagination-previous .icon svg,
      .list-pagination .pagination-pages .pagination-next .icon svg {
        display: block; }
    .list-pagination .pagination-pages .pagination-previous.disabled > .icon,
    .list-pagination .pagination-pages .pagination-next.disabled > .icon {
      fill: #c0ccd9; }

.key-info:not(:last-child) {
  margin-bottom: 2rem; }

.key-info-title {
  font-weight: 500;
  color: #212121;
  font-size: 1.6rem; }

.key-info-description {
  color: #4d5e71;
  font-size: 1.5rem; }

.key-info-item {
  display: flex; }
  .key-info-item .label {
    font-weight: 500;
    color: #4d5e71;
    font-size: 1.3rem;
    flex: 1; }
  .key-info-item .detail {
    color: #6c7f95;
    font-size: 1.3rem;
    flex: 2; }

.avatar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .avatar-img {
    background-color: #d54227;
    width: 15rem;
    height: 15rem;
    border-radius: 50%; }

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .loading-container .loading-circle {
    background-color: #d54227;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: loading 1.4s infinite; }
  .loading-container .loading-text {
    margin: 1rem;
    color: #d54227;
    font-size: 1.4rem;
    font-weight: 500; }
  .loading-container .error {
    margin: 1rem;
    color: #d54227;
    font-size: 1.4rem; }

.overlay-container {
  position: relative; }
  .overlay-container > * {
    width: 100%;
    height: 100%; }
  .overlay-container .overlay-content {
    position: absolute;
    background-color: #ffffff99;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }

.wrapper-loading {
  position: absolute;
  top: 45%;
  left: 50%; }

.loading-btn {
  position: absolute;
  opacity: 1;
  right: 0;
  margin-right: 2rem; }
  .loading-btn .loading-circle {
    width: 1.4rem;
    height: 1.4rem;
    background-color: #ffffff; }

@keyframes loading {
  0%,
  60%,
  100% {
    transform: scale(0);
    opacity: 0;
    animation-timing-function: ease-in; }
  20%,
  35% {
    transform: scale(1);
    opacity: 1;
    animation-timing-function: ease-out; } }

.component-frequent-requests .request {
  border-bottom: 0.1rem solid #c0ccd9;
  margin: 1.5rem 0;
  padding-bottom: 1.5rem; }
  .component-frequent-requests .request:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0; }
  .component-frequent-requests .request:hover .label {
    color: #212121; }
  .component-frequent-requests .request:hover .icon {
    fill: #d54227; }

.component-frequent-requests .icon {
  fill: #6c7f95;
  margin-left: 1rem; }

.landlord {
  display: flex;
  flex-direction: row;
  padding-top: 2rem;
  padding-bottom: 2rem; }
  .landlord-name {
    margin-right: 2rem;
    width: 35%; }
    .landlord-name .name {
      color: #212121;
      font-size: 1.6rem;
      font-weight: 500;
      display: flex;
      align-items: center; }
    .landlord-name .tenants {
      color: #6c7f95;
      font-size: 1.4rem;
      font-weight: 400; }
  .landlord-units {
    width: 65%; }
    .landlord-units .group {
      display: flex;
      flex-direction: row;
      padding-bottom: 0.3rem; }
      .landlord-units .group :last-child {
        padding: 0; }
      .landlord-units .group .primary-unit {
        color: #4d5e71;
        flex: 2;
        font-size: 1.4rem;
        font-weight: 500; }
      .landlord-units .group .secondary-unit {
        color: #6c7f95;
        flex: 2;
        font-size: 1.4rem;
        font-weight: 400; }
      .landlord-units .group .floor {
        flex: 1;
        color: #6c7f95;
        font-size: 1.4rem;
        font-weight: 400;
        text-align: center; }
  .landlord-contact-modal > .landlord-owners > * {
    margin-bottom: 1rem; }
    .landlord-contact-modal > .landlord-owners > *:last-child {
      margin-bottom: 0; }
  .landlord-contact-modal > .landlord-others {
    border-top: 0.1rem solid #c0ccd9;
    margin-top: 3rem;
    padding-top: 3rem; }
  .landlord-contact-modal .contact {
    display: flex;
    flex-direction: row; }
    .landlord-contact-modal .contact > .name {
      width: 35%;
      color: #212121;
      font-size: 1.6rem;
      font-weight: 500;
      display: flex;
      align-items: center; }
    .landlord-contact-modal .contact > .role {
      width: 35%;
      color: #6c7f95;
      font-size: 1.4rem;
      font-weight: 400; }

.component-property-rules .files-list {
  border-top: 0.1rem solid #c0ccd9;
  margin-top: 3rem;
  padding-top: 3rem; }

.form-contact-infos .tips,
.form-contact-preferences .tips,
.form-payment-preferences .tips,
.form-access-preferences .tips {
  display: none; }

.form-contact-infos .form-group,
.form-contact-preferences .form-group,
.form-payment-preferences .form-group,
.form-access-preferences .form-group {
  max-width: 34rem; }

@media screen and (min-width: 400px) {
  .form-contact-infos .form-textarea,
  .form-contact-preferences .form-textarea,
  .form-payment-preferences .form-textarea,
  .form-access-preferences .form-textarea {
    max-width: 34rem;
    width: 34rem; } }

.change-password-modal .modal-content {
  max-width: 50rem; }

.change-password-modal .form-footer {
  margin-top: 4rem; }

.contact-preferences {
  display: flex;
  flex-direction: column; }
  .contact-preferences .col-left {
    flex: 5; }
  .contact-preferences .col-right {
    flex: 4; }
  .contact-preferences .block-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem; }

.form-other-contacts .form-group {
  margin-bottom: 1rem; }

.form-other-contacts .form-submit {
  border-top: 0.1rem solid #c0ccd9;
  margin-top: 3rem;
  padding-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

.form-other-contacts .form-footer {
  margin-top: 3rem;
  justify-content: flex-end; }

@media screen and (min-width: 950px) {
  .form-contact-infos .tips,
  .form-payment-preferences .tips,
  .form-other-contacts .tips {
    display: block; }
  .contact-preferences {
    flex-direction: row; }
    .contact-preferences .block-content {
      margin: 0 0 0 6rem; } }

.members {
  margin-top: 3rem;
  width: 100%; }
  .members-title {
    font-weight: 500;
    margin-bottom: 1.5rem; }
  .members .member {
    align-items: start;
    display: flex;
    margin: 0 -2rem;
    padding: 1rem 1.5rem;
    text-align: left;
    white-space: nowrap; }
    .members .member-name {
      color: #212121;
      flex: 1.5;
      font-size: 1.6rem; }
    .members .member-email {
      color: #4d5e71;
      flex: 1.5;
      font-size: 1.4rem;
      overflow: hidden; }
    .members .member-tel {
      color: #4d5e71;
      flex: 1;
      font-size: 1.4rem;
      display: flex;
      flex-direction: column; }
    .members .member-role {
      color: #6c7f95;
      font-size: 1.4rem;
      flex: 1;
      line-height: 1.7rem;
      margin-right: 1rem; }
    .members .member-btn-close {
      cursor: pointer; }
      .members .member-btn-close .icon {
        display: block;
        height: 2.5rem;
        width: 2.5rem;
        fill: #d54227; }
    .members .member:nth-child(even) {
      background: #eff2f9; }
  @media screen and (min-width: 950px) {
    .members .member {
      margin: 0 0 0 -1.5rem; } }

.component-accountancy.my-accountancy .closing-in-progress {
  margin-top: 2rem;
  color: #4d5e71;
  font-size: 1.5rem;
  line-height: 2rem; }

.component-accountancy > div:not(:last-child) {
  border-bottom: 0.1rem solid #c0ccd9;
  margin-bottom: 3.5rem;
  padding-bottom: 4rem; }

.gm-details {
  color: #6c7f95;
  font-size: 1.6rem;
  line-height: 1.9rem; }
  .gm-details .gm-detail {
    align-items: center;
    display: flex;
    margin-bottom: 1.6rem; }
    .gm-details .gm-detail .icon {
      fill: #6c7f95;
      flex-grow: 0;
      margin-right: 1rem; }

.component-general-meeting .gm-button {
  display: flex;
  justify-content: center; }

.decisions {
  color: #4d5e71;
  font-size: 1.4rem;
  line-height: 3.2rem; }
  .decisions .decision-group {
    margin: 4rem 0 2rem; }
  .decisions-header {
    align-items: flex-start;
    color: #4d5e71;
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    line-height: 2rem;
    justify-content: space-between;
    flex: 1; }
    .decisions-header .row {
      display: flex;
      flex-direction: row;
      width: 100%; }
    .decisions-header .label {
      flex: 1;
      font-size: 1.6rem;
      font-weight: 500;
      color: #212121; }
    .decisions-header .thousands {
      color: #6c7f95; }
  .decisions-content {
    padding-bottom: 2rem; }
    .decisions-content .participant-item {
      align-items: flex-start;
      color: #4d5e71;
      display: flex;
      flex-direction: row;
      font-size: 1.5rem;
      line-height: 2rem;
      justify-content: space-between;
      padding: 1rem 1.5rem; }
      .decisions-content .participant-item .thousands {
        color: #6c7f95; }
      .decisions-content .participant-item:nth-child(odd) {
        background-color: #ffffff; }
      .decisions-content .participant-item:nth-child(even) {
        background-color: #f7faff; }
  .decisions-vote {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 2rem; }
    .decisions-vote > .summary {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1.4rem;
      line-height: 1.8rem;
      width: 25%;
      margin-right: 3rem; }
      .decisions-vote > .summary.label {
        display: flex;
        flex-direction: column; }
      .decisions-vote > .summary .votes {
        font-weight: 500; }
      .decisions-vote > .summary .icon {
        margin-right: 1rem; }
    .decisions-vote.voteYes .icon {
      fill: #3f995f; }
    .decisions-vote.voteNo .icon {
      fill: #d54227; }
    .decisions-vote.voteBlank .icon {
      fill: #4d5e71; }
    .decisions-vote .names {
      align-items: center; }
      .decisions-vote .names-list {
        columns: 1;
        column-width: 100%; }
      .decisions-vote .names .thousands {
        color: #6c7f95;
        margin-left: 1rem; }
    .decisions-vote:nth-child(even) {
      background: #ffffff; }
    .decisions-vote:nth-child(odd) {
      background: #f7faff; }
  .decisions .title {
    margin-bottom: 1rem; }
  .decisions .note {
    margin-bottom: 2rem;
    line-height: 2.2rem; }
    .decisions .note p {
      line-height: 2rem; }
  .decisions .detail {
    align-items: center;
    display: flex;
    margin-bottom: 1.5rem; }
    .decisions .detail .icon {
      flex-grow: 0;
      margin-right: 1rem; }
      .decisions .detail .icon svg {
        display: block; }
    .decisions .detail-label {
      line-height: 1.8rem; }
    .decisions .detail-vote {
      font-weight: 500; }
  .decisions .files {
    margin: 2rem 0 5rem; }
    .decisions .files .file {
      margin-bottom: 2rem; }
    .decisions .files .title {
      border-bottom: 0.1rem solid #c0ccd9;
      margin-bottom: 2rem;
      padding-bottom: 1rem; }
    .decisions .files .sub-items {
      margin-right: 0; }
  .decisions-majority {
    color: #6c7f95;
    text-align: right;
    font-style: italic; }

.participants-header {
  align-items: flex-start;
  color: #4d5e71;
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
  line-height: 2rem;
  justify-content: space-between;
  flex: 1; }
  .participants-header .label {
    flex: 1;
    font-size: 1.6rem;
    font-weight: 500;
    color: #212121; }
  .participants-header .thousands {
    color: #6c7f95; }

.participants-content .participant-item {
  align-items: flex-start;
  color: #4d5e71;
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
  line-height: 2rem;
  justify-content: space-between;
  padding: 1rem 1.5rem; }
  .participants-content .participant-item .thousands {
    color: #6c7f95; }
  .participants-content .participant-item:nth-child(odd) {
    background-color: #ffffff; }
  .participants-content .participant-item:nth-child(even) {
    background-color: #f7faff; }

.decisions,
.participants {
  border-top: 0.1rem solid #c0ccd9;
  margin-top: 4rem;
  padding-top: 4rem; }
  .decisions .see-all,
  .participants .see-all {
    border-top: 0.1rem solid #c0ccd9;
    display: block;
    font-weight: 500;
    margin-top: 2.5rem;
    padding-top: 1.5rem;
    text-align: center; }

.gm-button-bottom {
  margin-top: 2rem; }

@media screen and (min-width: 768px) {
  .decisions-line {
    flex-direction: row; }
  .decisions .names {
    flex-grow: 1; }
    .decisions .names-list {
      columns: 2;
      column-width: 50%; }
  .decisions .detail {
    flex-grow: 0;
    margin-bottom: 0;
    width: 28%; }
  .participants .names .name {
    align-items: center;
    flex-direction: row; } }

.component-file > :first-child {
  margin-top: 0; }

.component-file > * {
  border-bottom: 0.1rem solid #c0ccd9;
  margin-top: 3.5rem;
  padding-bottom: 1.5rem; }

.component-file > :last-child {
  border-bottom: 0; }

.file-header {
  color: #6c7f95; }

.file-summary {
  padding-bottom: 3rem; }
  .file-summary .title {
    font-size: 1.4rem; }
  .file-summary .summary-text {
    color: #4d5e71; }

.file-logs {
  color: #212121;
  font-size: 1.5rem; }
  .file-logs-list {
    display: flex;
    flex-direction: column; }
  .file-logs-item {
    display: flex;
    flex-direction: row;
    padding: 1rem 1.5rem; }
    .file-logs-item .is-key {
      font-weight: 500; }
    .file-logs-item:nth-child(even) {
      background-color: #f7faff; }
  .file-logs .description {
    align-items: center;
    flex: 3;
    display: flex;
    flex-direction: row; }
    .file-logs .description .icon {
      margin-left: 1rem; }
  .file-logs .date {
    flex: 1;
    color: #4d5e71;
    text-align: right; }
  .file-logs .see-all {
    margin-top: 2.3rem;
    font-weight: 500;
    justify-content: center;
    font-size: 1.4rem; }

.file-documents {
  padding-bottom: 3rem; }
  .file-documents .file-document-item {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .file-documents .file-document-item .icon {
      margin-right: 1.4rem;
      fill: #d54227; }
    .file-documents .file-document-item .name {
      align-items: center;
      color: #212121;
      cursor: pointer;
      display: flex;
      flex: 1;
      font-size: 1.5rem; }
    .file-documents .file-document-item .date {
      color: #6c7f95;
      font-size: 1.4rem; }
    .file-documents .file-document-item .size {
      color: #4d5e71;
      margin-left: 1.5rem; }

.file-infos {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem; }
  .file-infos-item {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 1.6rem; }
  .file-infos .label {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem; }
  .file-infos .icon {
    fill: #6c7f95;
    flex-grow: 0;
    margin-right: 1rem; }

.payment-modal .modal-header {
  border: 0;
  margin-bottom: 0; }

.payment-modal .modal-content {
  max-width: 55rem; }

.payment-modal-content {
  color: #6c7f95;
  display: flex;
  flex-direction: column; }
  .payment-modal-content .payment-header {
    display: flex;
    flex-direction: row;
    font-size: 2.4rem;
    line-height: 2.8rem;
    letter-spacing: 0.2rem;
    border-bottom: 0.1rem solid #c0ccd9;
    padding-bottom: 2rem; }
    .payment-modal-content .payment-header .payment-title {
      flex: 1; }
    .payment-modal-content .payment-header .payment-amount {
      flex: 0;
      font-weight: 500;
      white-space: nowrap; }
  .payment-modal-content .select-payment-method {
    display: flex;
    flex-direction: row;
    margin-top: 2.5rem; }
    .payment-modal-content .select-payment-method > .payment-method {
      flex: 1; }
  .payment-modal-content .payment-button {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 1rem; }
    .payment-modal-content .payment-button .btn {
      width: unset;
      margin-top: 0; }
    .payment-modal-content .payment-button .hint-secure {
      display: flex;
      flex-direction: row;
      font-size: 1.3rem;
      line-height: 1.6rem;
      flex: 1;
      margin-right: 2rem; }
      .payment-modal-content .payment-button .hint-secure .icon {
        fill: #6c7f95;
        margin-right: 1rem; }

.credit-card-form .credit-card-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem; }

.credit-card-form .credit-card-number,
.credit-card-form .credit-card-holder {
  width: 100%; }

.credit-card-form .StripeElement,
.credit-card-form .credit-card-holder > .form-input {
  -webkit-appearance: none;
  appearance: none;
  background: #eff2f9;
  border: 0.1rem solid transparent;
  border-radius: 0.4rem;
  color: #212121;
  font-size: 1.6rem;
  line-height: 2rem;
  outline: none;
  padding: 1rem 2rem;
  width: 100%; }
  .credit-card-form .StripeElement::placeholder, .credit-card-form .StripeElement:disabled, .credit-card-form .StripeElement--disabled, .credit-card-form .StripeElement--disabled::placeholder,
  .credit-card-form .credit-card-holder > .form-input::placeholder,
  .credit-card-form .credit-card-holder > .form-input:disabled,
  .credit-card-form .credit-card-holder > .form-input--disabled,
  .credit-card-form .credit-card-holder > .form-input--disabled::placeholder {
    color: #6c7f95; }
  .credit-card-form .StripeElement--focus, .credit-card-form .StripeElement:focus,
  .credit-card-form .credit-card-holder > .form-input--focus,
  .credit-card-form .credit-card-holder > .form-input:focus {
    border-color: #c0ccd9;
    box-shadow: 0 0.4rem 2rem rgba(77, 94, 113, 0.15); }
  .credit-card-form .StripeElement--invalid,
  .credit-card-form .credit-card-holder > .form-input--invalid {
    color: #d54227;
    border-color: #d54227; }

.credit-card-form .form-title {
  color: #6c7f95;
  font-weight: 400;
  margin-bottom: 0; }

.component-faq-categories-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2rem;
  background: 0;
  padding: 0;
  box-shadow: unset; }
  .component-faq-categories-grid .faq-block {
    display: flex;
    box-shadow: rgba(77, 94, 113, 0.25) 0 0.4rem 2rem;
    background-color: #ffffff;
    min-width: 49%;
    margin-bottom: 1rem;
    border-radius: 0.5rem; }
    .component-faq-categories-grid .faq-block a {
      padding: 3rem 6rem;
      width: 100%;
      text-align: center;
      color: #000000;
      font-size: 1.6rem; }
    .component-faq-categories-grid .faq-block :hover {
      transition: linear 0.1s;
      box-shadow: rgba(77, 94, 113, 0.25) 0 0.5rem 2.1rem; }
  @media screen and (max-width: 1024px) {
    .component-faq-categories-grid {
      flex-direction: column; } }

.faq-content {
  display: flex; }
  .faq-content .column .component-frequent-requests {
    margin-bottom: 0; }
  @media screen and (max-width: 1024px) {
    .faq-content {
      flex-direction: column; } }

.faq-post-list {
  display: flex;
  flex-direction: column;
  padding-top: 1rem; }
  .faq-post-list .category-title {
    font-size: 2.4rem;
    color: #000000;
    padding: 2rem 0;
    font-weight: 500; }
  .faq-post-list li::before {
    content: '\25CF';
    color: #d54227;
    position: absolute;
    margin-left: -2.6rem;
    text-align: center; }
  .faq-post-list li {
    padding: 1rem 0 1rem 3.2rem; }

.faq-category-block {
  height: 100%; }

.post-link {
  padding-bottom: 1rem;
  color: #4d5e71; }
  .post-link:hover {
    color: #000000;
    cursor: pointer; }

.faq-navigation-title a,
.faq-navigation-title span {
  color: #d54227; }

.faq-navigation-title .navigation-arrow {
  color: #4d5e71;
  padding: 0 1rem; }

.post-content .body {
  display: flex;
  flex-direction: column;
  color: #4d5e71; }
  .post-content .body .embedded-asset-test {
    text-align: center; }
    .post-content .body .embedded-asset-test img {
      max-width: 100%; }
  .post-content .body h1,
  .post-content .body h2,
  .post-content .body h3,
  .post-content .body h4,
  .post-content .body h5 {
    color: #000000; }
  .post-content .body h1 {
    font-size: 2.4rem;
    padding: 2.4rem 0 1.4rem 0;
    font-weight: 500; }
  .post-content .body h2,
  .post-content .body h3,
  .post-content .body h4,
  .post-content .body h5 {
    font-size: 2rem;
    padding: 2rem 0; }
  .post-content .body a {
    color: #d54227;
    border-bottom: solid 0.2rem #d54227; }
  .post-content .body p {
    padding: 0.5rem 0; }
  .post-content .body b {
    font-weight: 500; }
  .post-content .body ol {
    padding: 1rem 0 1rem 3.2rem; }
  .post-content .body ol li {
    counter-increment: list;
    list-style-type: none;
    position: relative;
    padding-top: 1rem; }
  .post-content .body ol li:before {
    color: #d54227;
    content: counter(list) ".";
    left: -3.2rem;
    position: absolute;
    text-align: center;
    width: 2.6rem;
    font-weight: 400; }
  .post-content .body ul {
    padding: 0rem 0 1rem 3.2rem; }
  .post-content .body ul li::before {
    content: '\25CF';
    color: #d54227;
    position: absolute;
    margin-left: -2.6rem;
    text-align: center; }
  .post-content .body li {
    padding-top: 0.5rem; }
    .post-content .body li > p {
      padding: 0; }

.ais-InstantSearch__root {
  width: 100%;
  margin: auto; }

.react-autosuggest__container {
  position: relative; }

.react-autosuggest__container--open {
  width: 100%; }

.react-autosuggest__input {
  align-items: center;
  background: #eff2f9;
  border-radius: 10rem;
  display: flex;
  justify-content: space-between;
  padding: 0.6rem 2rem;
  border: none;
  color: #212121;
  font-family: "Rubik", sans-serif;
  font-size: 1.6rem;
  line-height: 2rem;
  outline: none;
  width: 100%;
  height: 5rem; }
  .react-autosuggest__input::placeholder {
    color: #6c7f95; }

.react-autosuggest__suggestions-container {
  position: absolute;
  display: flex;
  width: 100%;
  z-index: 2;
  background: #eff2f9;
  border-radius: 2rem;
  margin-top: 1.6rem; }

.react-autosuggest__suggestions-list {
  width: 100%; }

.react-autosuggest__suggestion {
  padding: 0.5rem 2rem;
  cursor: pointer; }
  .react-autosuggest__suggestion:hover {
    background: rgba(77, 94, 113, 0.15); }
  .react-autosuggest__suggestion:first-child {
    border-radius: 2rem 2rem 0 0; }
  .react-autosuggest__suggestion:last-child {
    border-radius: 0 0 2rem 2rem; }
  .react-autosuggest__suggestion:only-child {
    border-radius: 2rem; }

.column
> .component
> .ais-InstantSearch__root
> .react-autosuggest__container
> .react-autosuggest__suggestions-container {
  position: inherit;
  width: 100%; }
  .column
> .component
> .ais-InstantSearch__root
> .react-autosuggest__container
> .react-autosuggest__suggestions-container .react-autosuggest__suggestions-list > .react-autosuggest__suggestion {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .column
> .component
> .ais-InstantSearch__root
> .react-autosuggest__container
> .react-autosuggest__suggestions-container .react-autosuggest__suggestions-list > .react-autosuggest__suggestion a {
      padding: 3rem 1rem 1rem 1rem; }

.page-404 .content-error {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 2rem 6rem;
  max-width: 80rem;
  width: 100%; }
  .page-404 .content-error-description {
    margin: 0 8rem;
    max-width: 30rem;
    width: 100%; }
  .page-404 .content-error .logo {
    margin-bottom: 10rem; }
  .page-404 .content-error h3 {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 3.6rem;
    margin-bottom: 1rem; }
  .page-404 .content-error p {
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-bottom: 1rem; }
  .page-404 .content-error .contact-us {
    font-size: 1.8rem;
    font-weight: 500;
    display: unset; }

.my-property .component-landlords .landlord {
  margin: 0 -2rem;
  padding: 2rem 2rem; }
  @media screen and (min-width: 1280px) {
    .my-property .component-landlords .landlord {
      margin: 0 -4rem;
      padding: 2rem 4rem; } }
  .my-property .component-landlords .landlord:nth-child(odd) {
    background-color: #f7faff; }
  .my-property .component-landlords .landlord:hover {
    background: #eff2f9;
    cursor: pointer; }

.my-property .component-property-info .property-avatar {
  margin-bottom: 2rem;
  margin-top: 2rem;
  background-color: unset; }

.accountancy .accountancy-select-period {
  align-items: center;
  display: flex;
  position: relative;
  line-height: 4rem; }
  .accountancy .accountancy-select-period .icon {
    position: absolute;
    right: 0;
    width: 1.2rem;
    margin-right: 2rem; }
    .accountancy .accountancy-select-period .icon svg {
      fill: #d54227; }
  .accountancy .accountancy-select-period select {
    background: #eff2f9;
    border: 0.1rem solid #eff2f9;
    border-radius: 2rem;
    padding-right: 4.5rem;
    padding-left: 2.5rem;
    color: #6c7f95;
    cursor: pointer;
    font-family: "Rubik", sans-serif;
    font-size: 1.5rem;
    line-height: 4rem;
    outline: none;
    width: 100%;
    -webkit-appearance: none;
    appearance: none; }

.accountancy .tip {
  border-top-color: #c0ccd9; }

@media screen and (min-width: 950px) {
  .accountancy .tip {
    border-left-color: #c0ccd9;
    margin-left: 0; } }

.tempList {
  bottom: 0;
  display: flex;
  position: fixed;
  right: 0; }
  .tempList li {
    margin-right: 1rem; }
    .tempList li a {
      color: #212121; }
      .tempList li a.active, .tempList li a:hover {
        color: #d54227; }

.content-onboarding {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 2rem auto;
  max-width: 50rem;
  width: 100%; }
  .content-onboarding .component {
    position: relative;
    width: 100%; }
  .content-onboarding .notification {
    position: absolute;
    left: 0;
    top: -6.5rem; }
  .content-onboarding .form-footer {
    border-top: 0.1rem solid #c0ccd9;
    border-bottom: none;
    margin: 4rem 0 0;
    padding: 4rem 0 0; }
  .content-onboarding .component.has-notification {
    margin-top: 5rem; }
  .content-onboarding .form {
    width: 100%; }

.form-login .form-disabled .form-title {
  color: #212121; }

.form-login .form-disabled .form-input {
  color: #c0ccd9; }

.form-login .form-input {
  color: #6c7f95; }

.form-login .create-account-link {
  margin-top: 2rem; }

.form-login .associate-comment {
  color: #6c7f95;
  font-size: 1.5rem; }

.form-infos .form-group-container {
  margin-bottom: 4rem; }

.form-contact .block-content {
  box-shadow: none;
  padding: 2rem 2rem 0; }
  .form-contact .block-content .btn {
    display: inline-flex; }

.form-contact .form-footer {
  border: 0;
  margin-top: 4rem;
  padding-top: 0rem; }
  .form-contact .form-footer .btn {
    max-width: 25rem; }

.form .form-group .form-submit {
  display: flex;
  justify-content: flex-end; }

.form .members {
  border-top: 0.1rem solid #c0ccd9;
  margin: 4rem 0 0;
  padding: 4rem 0 0; }

.component-onboarding-complete {
  align-items: flex-start;
  background: #ffffff url(/static/img/onboarding-complete.png) no-repeat right bottom;
  background-size: 39.7rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 26rem; }
  .component-onboarding-complete .title {
    margin-bottom: 4rem; }

.form-success p {
  font-size: 1.5rem;
  margin-bottom: 2rem; }

@media screen and (min-width: 1024px) {
  .content-onboarding {
    flex-direction: row;
    margin: 0;
    max-width: 100%; }
    .content-onboarding .component {
      width: 50rem; }
    .content-onboarding .component.has-notifcation {
      margin-top: 0; }
  .form-contact .block-content {
    padding: 4rem 6rem 0; } }

.help-component {
  display: flex;
  flex-direction: column;
  width: 100%; }
